

import { Vue, Component } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'

@Component({
  components: { },
  filters: {
    getStatusName: function (value: any) {
      if (value) {
        if (value === '0') {
          return '待盘点'
        } else if (value === '1') {
          return '盘点中'
        } else {
          return '已完成'
        }
      }
    }
  }
})
@KeepAlive
export default class InsectPest extends Vue {
  private InspectionType = [
    {
      label: '苗木',
      value: '1'
    },
    {
      label: '分区',
      value: '2'
    }
  ]

  private InspectionStatus = [
    {
      label: '待盘点',
      value: '0'
    },
    {
      label: '盘点中',
      value: '1'
    },
    {
      label: '已完成',
      value: '2'
    }
  ]

  private seedlingDate = []
  private searchInfo = {
    checkName: '', // 盘点名称
    checkUserName: '', // 盘点人员姓名
    projectId: '', // 所属项目
    checkType: '', // 盘点类型
    checkStatus: '', // 盘点状态
    startTime: '', // 开始时间
    endTime: '' // 结束时间
  }

  private loading = false
  private tableData = []
  private total = 0
  private page = 1
  private size = 10

  get projectList () {
    return this.$store.state.projectList
  }

  get userId () {
    return this.$store.state.userInfo.userId
  }

  created () {
    this.loadData()
  }

  loadData () {
    // console.log('loadData')
    this.loading = true
    this.$axios.get(this.$apis.seedlingInspection.selectYhSeedlingCheckByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  // 去新增页面
  goAdd () {
    this.$router.push({ name: 'seedlingInspectionAdd' })
  }

  onDetail (checkId: any) {
    this.$router.push({
      path: '/seedlingInspection/detail',
      query: {
        checkId
      }
    })
  }

  onSeedlingInspection (checkId: any) {
    this.$router.push({
      path: '/seedlingInspection/seedlingInspection',
      query: {
        checkId: checkId,
        isEdit: true as any
      }
    })
  }

  // onStart (checkId: string) {
  //   this.$confirm('确认开始吗, 是否继续?', '提示', {
  //     confirmButtonText: '确定',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }).then(() => {
  //     this.$axios.post(this.$apis.seedlingInspection.startYhSeedlingCheck, { checkId }).then(() => {
  //       this.$message.success('开始成功')
  //       this.loadData()
  //     })
  //   })
  // }

  onDelete (checkId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.seedlingInspection.deleteYhSeedlingCheck, { checkId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }

  selectTime1 (val: any) {
    // console.log('时间', val)
    if (!val) {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    } else {
      this.searchInfo.startTime = this.seedlingDate[0]
      this.searchInfo.endTime = this.seedlingDate[1]
    }
  }
}
